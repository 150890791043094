import './App.css';
import Header from './Componentes/Header';
import Footer from './Componentes/Footer';
import Inicio from './Paginas/Inicio';
import Sudaderas from './Paginas/Sudaderas';
import Camisetas from './Paginas/Camisetas';
import SS23 from './Paginas/SS23';
import FW24 from './Paginas/FW24';
import Detalles from './Componentes/Detalles';
import DetallesPink from './Paginas/DetallesPink';
import DetallesCropped from './Paginas/DetallesCropped';
import DetallesSudadera from './Paginas/DetallesSudadera';
import DetallesFlor from './Paginas/DetallesFlor';
import Conocenos from './Paginas/Conocenos';
import ScrollToTop from './Componentes/ScrollToTop';
import {  BrowserRouter as Router, Routes, Route } from "react-router-dom";




function App() {

  return ( 
    <div className="App">
     
     <ScrollToTop />
      <Header />

      <main>
        <Routes>
          <Route path='/' element={<Inicio />} />
          <Route path='/Sudaderas' element={<Sudaderas />}/>
          <Route path='/Camisetas' element={<Camisetas />}/>
          <Route path='/SS23' element={<SS23 />}/>
          <Route path='/FW24' element={<FW24 />}/>
          <Route path='/Detalles' element={<Detalles />}/>
          <Route path='/DetallesPink' element={<DetallesPink />} />
          <Route path='/DetallesCropped' element={<DetallesCropped />} />
          <Route path='/DetallesSudadera' element={<DetallesSudadera />}/>
          <Route path='/DetallesFlor'  element={<DetallesFlor />}/>
          <Route path='/Conocenos' element={<Conocenos />} />
        </Routes>
      </main>

      
      <Footer />


    </div>
   

  );
}

export default App;

import React from "react"
import './Card.css'
import { Link } from "react-router-dom";


function Card({ title, imageUrl, body, alt, linkto }) {
  return (
    <div className="card-container">

      <div className="image-container">
        <Link to={linkto}>
          <img className="imagen" src={imageUrl} alt={alt} />
        </Link>
      </div>

      <div className="card-content">
        <div>
          <h3>{title}</h3>
        </div>
        <div>
          <p>{body}</p>
        </div>
      </div>


    </div>
  );
};


export default Card;
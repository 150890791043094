import React from "react";
import Card from "../Componentes/Card";
import { useEffect, useState } from "react";



function Inicio() {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex(currentIndex => (currentIndex + 1) % 3);
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>

    <div className="slider-box">
        <ul>
          <li><img className={`slider-imagen ${currentIndex === 0 ? 'visible' : ''}`} src="../imagenes/frontal.png"/></li>
          <li><img className={`slider-imagen ${currentIndex === 1 ? 'visible' : ''}`} src="../imagenes/img-front.png" /> </li>
          <li><img className={`slider-imagen ${currentIndex === 2 ? 'visible' : ''}`} src="../imagenes/img-front1.png" /> </li>
        </ul>
    </div>

    <p><strong>PRENDAS DESTACADAS</strong></p>

    <div className='tarjeta-prenda'>

      <Card 
        title='Camiseta pink sky'
        imageUrl='imagenes/delanteroblanca.jpg'
        body='19.90€'
        alt='Camiseta pink sky'
        linkto='/DetallesPink'
      />

      <Card 
        title='Camiseta flower inspo'
        imageUrl='imagenes/caminegra.jpg'
        body='19.90€'
        alt='Camiseta flower inspo'
        linkto='/DetallesFlor'
      />
    </div>
    
    </>
  );
};

export default Inicio;

import Detalles from "../Componentes/Detalles";
import '../Componentes/Detalles.css';


function DetallesCropped() {
  return (
    <div>
      <Detalles 
      imagen1='../imagenes/cropped.png'
      imagen2='../imagenes/img2.jpg'
      imagen3='../imagenes/img6.jpg'
      alt='Camiseta cropped'
      nombre='Camiseta cropped'
      precio='14,90€'
      descripcion='Camiseta 100% algodón con serigrafía.'
      boton={<stripe-buy-button buy-button-id="buy_btn_1P3wxn2KmBAIwD1N1D0TyAbC" publishable-key="pk_live_51P2tSS2KmBAIwD1NlxzfrWUYTsU2OGAR29TMw1LA555l0HsKcGd7RWfrxHSrLmJLJ1qutob8yzoeMTMPl7NMI1yB00WLJLg2DA"></stripe-buy-button>}
      />
    </div>
  )
};


export default DetallesCropped;
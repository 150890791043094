
function Conocenos() {
  return (
    <>
    <h1 className="titulo-conocenos">CONÓCENOS</h1>
    <div className="conocenos">
      <p className="info-conocenos">
       SOMOS UNA MARCA DE MODA URBANA LOCAL, ARRAIGADA 
       EN LA AUTENTICIDAD Y LA CREATIVIDAD DE NUESTRA COMUNIDAD. EN CADA 
       PRENDA QUE DISEÑAMOS Y PRODUCIMOS BUSCAMOS REFLEJAR LA DIVERSIDAD
       DE EXPRESIONES QUE NOS RODEAN. NUESTRA PASIÓN POR LA MODA SE FUSIONA
       CON NUESTRO COMPROMISO CON LA INNOVACIÓN, CREANDO PIEZAS ÚNICAS QUE
       CAPTURAN LA ESENCIA DEL ESTILO CONTEMPORÁNEO.
      </p>
      <video src="../imagenes/promo.mp4" width={'80%'} height={'auto'} autoPlay loop muted controls></video>
    </div>
    </>
  );
};


export default Conocenos;
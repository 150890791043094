import { useState } from "react";

function Detalles({ imagen1, imagen2, imagen3, alt, nombre, precio, descripcion, boton }) {
  const [currentSlide, setCurrentSlide] = useState(1);

  const cambiarImagen = (slideNumber) => {
    setCurrentSlide(slideNumber);
  };

  return (
    <div className="tarjeta">

      <div className="container">

        <div className="slider">
          <img src={imagen1} alt={alt} style={{ display: currentSlide === 1 ? 'block' : 'none'}} />
          <img src={imagen2} alt={alt} style={{ display: currentSlide === 2 ? 'block' : 'none'}} />
          <img src={imagen3} alt={alt} style={{ display: currentSlide === 3 ? 'block' : 'none'}} />
        </div>
  

        <div className="botones">
          <button onClick={() => cambiarImagen(1)}>◯</button>
          <button onClick={() => cambiarImagen(2)}>◯</button>
          <button onClick={() => cambiarImagen(3)}>◯</button>
        </div>

      </div>
    

      <div className="tarjeta-info">
        <h1>{nombre}</h1>
        <p>{precio}</p>
        <p>{descripcion}</p>
        <div>
          {boton}
        </div>
      </div>

    </div>
  );
};


export default Detalles;
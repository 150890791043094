import Detalles from "../Componentes/Detalles";
import '../Componentes/Detalles.css';
import ScrollToTop from "../Componentes/ScrollToTop";


function DetallesPink() {
  return (
    
      <div className='disposicion'>
        <Detalles 
        imagen1='../imagenes/delanteroblanca.jpg'
        imagen2='../imagenes/traseroblanca.jpg'
        imagen3='../imagenes/img1.jpg'
        alt='Camiseta pink sky'
        nombre='Camiseta pink sky'
        precio='19,90€'
        descripcion='Camiseta 100% algodón con serigrafía.'
        boton={<stripe-buy-button buy-button-id="buy_btn_1P3val2KmBAIwD1N0ff6777V" publishable-key="pk_live_51P2tSS2KmBAIwD1NlxzfrWUYTsU2OGAR29TMw1LA555l0HsKcGd7RWfrxHSrLmJLJ1qutob8yzoeMTMPl7NMI1yB00WLJLg2DA"></stripe-buy-button>}
        />
      </div>
      
  )
};


export default DetallesPink;
import Card from "../Componentes/Card";


function FW24() {
  return (
    <div>
      <h1>FW24</h1>

      <div className="tarjeta-prenda">
      <Card 
          title='Camiseta pink sky'
          imageUrl='imagenes/delanteroblanca.jpg'
          body='19.90€'
          alt='Camiseta pink sky'
          linkto='/DetallesPink'
        />
      </div>

    </div>
  );
};

export default FW24;

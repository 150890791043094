import Card from "../Componentes/Card";



function SS23() {
  return (
    <div>
      <h1>SS23</h1>

      <div className="tarjeta-prenda">
      <Card 
        title='Camiseta flower inspo'
        imageUrl='imagenes/caminegra.jpg'
        body='19.90€'
        alt='Camiseta flower inspo'
        linkto='/DetallesFlor'
      />

      <Card 
        title='Camiseta cropped'
        imageUrl='imagenes/cropped.png'
        body='14.90€'
        alt='Camiseta cropped'
        linkto='/DetallesCropped'
      />

      <Card 
        title='Sudadera logo'
        imageUrl='imagenes/sudadera-frontal.jpg'
        body='39.90€'
        alt='Sudadera logo'
        linkto='/DetallesSudadera'
      />
      
      </div>
    </div>
  );
};

export default SS23;

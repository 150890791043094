import { Link } from "react-router-dom";
import Card from "../Componentes/Card";



function Sudaderas() {
  return (
    <>
    <h1>SUDADERAS</h1>
    
    <div className="tarjeta-prenda">
      <Card 
        title='Sudadera logo'
        imageUrl='../imagenes/sudadera.png'
        body='39,90€'
        alt='Sudadera logo'
        linkto='/DetallesSudadera'
      />
    </div>
    </>
  );
};


export default Sudaderas;


import React from "react";
import Aside from "./Aside"
import { Link } from "react-router-dom";


function Header() {
  return (
    <div className='encabezado'>
     
      <Aside />
    
      <Link to='/'><img className="logo" src="./imagenes/logo.jpg" alt="Logo de Dianthus" /></Link>
     
      <a className="enlace" href="https://www.instagram.com/d_i_a_n_t_h_u_s/" target="_blank" rel="noopener noreferer"><img src="../imagenes/enlace.jpg" /></a>
      
      
    </div>
  );
};

export default Header;
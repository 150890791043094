
function Footer() {
  function copiarUrl() {
    let url=document.getElementById('url');
    let button=document.getElementById('botonCopiar');

    navigator.clipboard.writeText(url.textContent);
    button.textContent='✔️';
  }

  return (
    <>
    <div className="info">

      <span>
        Información de contacto
      </span>
      
      <div className="correo">
      <span id="url">
        dianthuscommunity@gmail.com
      </span>
      <button className="boton-correo" id="botonCopiar" onClick={copiarUrl}>
        <img src="../imagenes/icono-copiar.png"/>
      </button>
      </div>

    </div>
    </>
    
  );
};

export default Footer;



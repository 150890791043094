import React from "react";
import { Link } from "react-router-dom";



function Aside() {

  function toggleMenu() {
    var menu = document.querySelector('.desplegable');
    menu.classList.toggle('desplegable-visible');
  };

  return (

    <div className="menu" onClick={toggleMenu}>

      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" /></svg>
        
      <nav>
        <ul className="desplegable">

          <li><strong>ROPA</strong></li>

          <li><Link to='/Sudaderas' style={{textDecoration:'none', color:'black'}}>SUDADERAS</Link></li>
          <li><Link to='/Camisetas' style={{textDecoration:'none', color:'black'}}>CAMISETAS</Link></li>

          <li><strong>COLECCIONES</strong></li>

          <li><Link to='/SS23' style={{textDecoration:'none', color:'black'}}>SS23</Link></li>
          <li><Link to='/FW24' style={{textDecoration:'none', color:'black'}}>FW24</Link></li>

          <li><Link to='/Conocenos' style={{textDecoration:'none', color:'black'}}>CONÓCENOS</Link></li>

        </ul>
      </nav>

    

    </div>

  );
};

export default Aside;
import Detalles from "../Componentes/Detalles";
import '../Componentes/Detalles.css';


function DetallesSudadera() {
  return (
    <div>
      <Detalles 
      imagen1='../imagenes/sudadera-frontal.jpg'
      imagen2='../imagenes/sudadera-trasero.jpg'
      imagen3='../imagenes/img4.jpg'
      alt='Sudadera logo'
      nombre='Sudadera logo'
      precio='39,90€'
      descripcion='Sudadera con serigrafía.'
      boton={<stripe-buy-button buy-button-id="buy_btn_1P3wvi2KmBAIwD1NoQtaEs5X" publishable-key="pk_live_51P2tSS2KmBAIwD1NlxzfrWUYTsU2OGAR29TMw1LA555l0HsKcGd7RWfrxHSrLmJLJ1qutob8yzoeMTMPl7NMI1yB00WLJLg2DA"></stripe-buy-button>}
      />
    </div>
  )
};


export default DetallesSudadera;
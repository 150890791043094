import Card from "../Componentes/Card";
import ScrollToTop from "../Componentes/ScrollToTop";

function Camisetas() {
  return (
    <div>
      <h1>CAMISETAS</h1>

      <div className="tarjeta-prenda">

        <Card 
          title='Camiseta pink sky'
          imageUrl='imagenes/delanteroblanca.jpg'
          body='19.90€'
          alt='Camiseta pink sky'
          linkto='/DetallesPink'
        />

        <Card 
          title='Camiseta flower inspo'
          imageUrl='imagenes/caminegra.jpg'
          body='19.90€'
          alt='Camiseta flower inspo'
          linkto='/DetallesFlor'
        />

        <Card 
          title='Camiseta cropped'
          imageUrl='imagenes/cropped.png'
          body='14.90€'
          alt='Camiseta cropped'
          linkto='/DetallesCropped'
        />

      </div>

    </div>
  );
};

export default Camisetas;

import Detalles from "../Componentes/Detalles";
import '../Componentes/Detalles.css';


function DetallesFlor() {
  return (
    <div>
      <Detalles 
      imagen1='../imagenes/caminegra.jpg'
      imagen2='../imagenes/caminegraback.jpg'
      imagen3='../imagenes/img5.jpg'
      alt='Camiseta flower inspo'
      nombre='Camiseta flower inspo'
      precio='19,90€'
      descripcion='Camiseta 100% algodón con serigrafía.'
      boton={<stripe-buy-button buy-button-id="buy_btn_1P3wse2KmBAIwD1NJHxewrl4" publishable-key="pk_live_51P2tSS2KmBAIwD1NlxzfrWUYTsU2OGAR29TMw1LA555l0HsKcGd7RWfrxHSrLmJLJ1qutob8yzoeMTMPl7NMI1yB00WLJLg2DA"></stripe-buy-button>}
      />
    </div>
  )
};


export default DetallesFlor;